import * as tocbot from "tocbot";

setTOC();
window.onresize = setTOC;

function setTOC() {
  if (window.innerWidth > 992) {
    tocbot.init({
      tocSelector: ".desktop-only .wiki-toc",
      contentSelector: ".wiki-content",
      collapseDepth: 6,
      headingsOffset: 120,
      scrollSmoothOffset: -120,
      scrollSmoothDuration: 200,
      hasInnerContainers: true,
    });
    tocbot.refresh();
  } else {
    tocbot.init({
      tocSelector: ".mobile-only .wiki-toc",
      contentSelector: ".wiki-content",
      collapseDepth: 6,
      headingsOffset: 80,
      scrollSmoothOffset: -80,
      scrollSmoothDuration: 200,
      hasInnerContainers: true,
    });
    tocbot.refresh();
  }
}
